@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --primary: #020132;
    --secondary: #eae6db;
}

@font-face {
    font-family: SF;
    src: url('assets/fonts/sanfranciscodisplay-regular-webfont.woff') format("woff");
}

.font-didot {
    font-family: 'Didot', sans-serif;
}

body{
    background-color: var(--primary);
    font-family: 'Didot', sans-serif;
}

@font-face {
    font-family: 'WeddingParadise';
    src: url('./assets/fonts/Wedding_Paradise.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Magiesta';
    src: url('./assets/fonts/Magiesta.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Apostrophic';
    src: url('./assets/fonts/APOSTROPHIC.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: NewYork;
    src: url('assets/fonts/NewYork.otf') format("opentype");
}



.magiesta {
    font-family: 'Magiesta', sans-serif;
}

.wedding-paradise {
    font-family: 'WeddingParadise', sans-serif;
}

.apostrophic {
    font-family: 'Apostrophic', sans-serif;
}

.new-york {
    font-family: 'NewYork', sans-serif;
}


.bg-primary {
    background-color: var(--primary);
}

.border-primary {
    border-color: var(--primary);
}

.text-primary {
    color: var(--primary);
}

.bg-secondary {
    background-color: var(--secondary);
}

.text-secondary {
    color: var(--secondary);
}

